import { ACCESS_KEY, SECRET_KEY } from './Amazon';

const REGION = 'us-east-1';
const API_VERSION = '2010-12-01';

export const SES_CONFIG = {
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_KEY
  },
  apiVersion: API_VERSION
};

export const DEFAULT_PARAMS = {
  Source: 'telmgoncalves@gmail.com', // replace with hd.photographers@gmail.com
  Template: 'OrderTemplate'
};
