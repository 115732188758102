import React from 'react';

import Layout from '../components/Layout';
import OrderConfirmation from '../components/OrderConfirmation';
import SEO from '../components/seo';
import { KEYWORDS } from '../constants/Keywords';

const Homepage = () => (
  <Layout>
    <SEO title="Confirmação de Encomenda" keywords={[...KEYWORDS]} />

    <OrderConfirmation />
  </Layout>
);

export default Homepage;
