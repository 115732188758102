import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { sumBy } from 'lodash';
import AWS from 'aws-sdk';
import { ShoppingBag } from 'react-feather';

import { S3_ENDPOINT } from '../../constants/External';
import { GALLERY_PATH } from '../../constants/Routes';
import { Title, Anchor, Button } from '../../styles/base'
import Loader from '../../images/Loader.svg';
import { SES_CONFIG, DEFAULT_PARAMS } from '../../constants/SES';

import { OrderTableContainer, OrderForm } from './style';

class OrderConfirmation extends Component {
  state = {
    order: {},
    details: {
      name: '',
      email: '',
      phone: '',
      address: ''
    },
    sendingEmail: false,
    orderCompleted: false,
    sendingEmailError: undefined
  }

  componentDidMount() {
    const localCurrentOrder = localStorage.getItem('currentOrder');
    const { currentOrder } = this.state;

    this.setState({
      ...this.state,
      order: JSON.parse(localCurrentOrder) || currentOrder
    });
  }

  canSubmitForm() {
    const { name, email, phone, address } = this.state.details;

    return name !== '' && email !== '' && phone !== '' && address !== '';
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      ...this.state,
      details: {
        ...this.state.details,
        [name]: value
      }
    });
  }

  photoNameChecker = (photo) => {
    var photoName;
    const name = photo.split('/')[2];

    if (name.toLowerCase().includes('.jpg')) {
      photoName = name;
    } else {
      photoName = photo.split('/')[3];
    }

    return photoName;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      ...this.state,
      sendingEmail: true
    });

    const {
      details: {
        name,
        email,
        phone,
        address
      },
      order: {
        photos,
        albumName
      }
    } = this.state;

    const mappedPhotos = photos.map(({ photo, quantity }) => ({
      photo: `${S3_ENDPOINT}${photo}`,
      name: this.photoNameChecker(photo),
      quantity: quantity
    }));

    const escapedPhotos = JSON.stringify(mappedPhotos);

    var params = {
      ...DEFAULT_PARAMS,
      Destination: {
        ToAddresses: [
          'telmgoncalves@gmail.com',
          'hd.photographers@gmail.com',
          `${email}`
        ]
      },
      TemplateData: `{ \"albumName\": \"${albumName}\", \"name\":\"${name}\", \"email\":\"${email}\", \"phone\":\"${phone}\", \"address\":\"${address}\", \"photos\": ${escapedPhotos} }` /* eslint-disable-line no-useless-escape */
    };

    const sendEmail = new AWS.SES(SES_CONFIG).sendTemplatedEmail(params).promise();

    sendEmail
      .then(() => {
        typeof localStorage !== 'undefined' && localStorage.removeItem('currentOrder');

        this.setState({
          ...this.state,
          orderCompleted: true
        });

        setTimeout(() => {
          window.location.assign('/');
        }, 15000)
      })
      .catch(err => {
        this.setState({
          ...this.state,
          sendingEmail: false,
          sendingEmailError: err
        });

        console.log(err); // Print console.log for testing purposes
      })
  }

  render() {
    const {
      order: { photos, albumSlug },
      details: { name, email, phone, address },
      sendingEmail,
      orderCompleted,
      sendingEmailError
    } = this.state;

    return (
      <div>
        {!orderCompleted ? (
          <div>
            <Title>Confirmar Encomenda</Title>

            <Grid>
              <Row>
                <Col md={6}>
                  <OrderTableContainer>
                    <table>
                      <thead>
                        <tr>
                          <th>Foto</th>
                          <th>REF.</th>
                          <th>Quantidade</th>
                        </tr>
                      </thead>

                      <tbody>
                        {photos && photos.map(photo => (
                          <tr key={photo.photo}>
                            <td>
                              <img src={`${S3_ENDPOINT}${photo.photo}`} alt='Foto Encomenda' />
                            </td>

                            <td>
                              {this.photoNameChecker(photo.photo)}
                            </td>

                            <td>
                              {photo.quantity}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }}>Total</td>
                          <td style={{ border: 'none' }}>
                            <strong>
                              {sumBy(photos, (el) => el.quantity)}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div style={{
                      textAlign: 'center'
                    }}>
                      <Anchor to={`${GALLERY_PATH}${albumSlug}#order-mode`}>
                        Alterar encomenda
                      </Anchor>
                    </div>
                  </OrderTableContainer>
                </Col>

                <Col md={6}>
                  {!sendingEmail ?
                    <OrderForm onSubmit={this.handleSubmit}>
                      <h5 style={{
                        color: '#555',
                        fontWeight: 500
                      }}>
                        Preencha todos os campos para realizar a sua encomenda
                      </h5>

                      {sendingEmailError &&
                        <div style={{ margin: '30px 0' }}>
                          <span style={{
                            color: '#f9a11b',
                            fontSize: 11,
                            background: 'rgba(249, 161, 26, .2)',
                            padding: '8px 15px',
                            borderRadius: 6
                          }}>
                            Não foi possível realizar a encomenda, entre em contacto connosco por favor. <br />
                          </span>
                        </div>
                      }

                      <label htmlFor="name">Nome</label>
                      <input type="text" name="name" onChange={this.handleChange} value={name} />

                      <label htmlFor="email">Email</label>
                      <input type="text" name="email" onChange={this.handleChange} value={email} />

                      <label htmlFor="phone">Número de telefone</label>
                      <input type="text" name="phone" onChange={this.handleChange} value={phone} />

                      <label htmlFor="address">Morada</label>
                      <input type="text" name="address" onChange={this.handleChange} value={address} />

                      <div style={{
                        fontSize: 11,
                        lineHeight: 1.5,
                        marginBottom: 30,
                        color: '#888'
                      }}>
                        Verifique que escolheu as quantidades e as fotos que desejava antes de clicar em <strong>Realizar encomenda</strong>, receberá um email de seguida com a confirmação da encomenda.
                      </div>

                      <Button
                        style={{ width: '100%' }}
                        disabled={!this.canSubmitForm()}
                      >
                        Realizar encomenda
                      </Button>
                    </OrderForm>
                  :
                  <div style={{ margin: 30, padding: '120px 0', textAlign: 'center' }}>
                    <Loader />

                    <h3 style={{
                      fontWeight: 500,
                      fontSize: 18,
                      color: '#999',
                      marginTop: 30
                    }}>
                      A processar encomenda, aguarde ...
                    </h3>
                  </div>
                  }
                </Col>
              </Row>
            </Grid>
          </div>
        ) : (
          <Grid>
            <Row center='md'>
              <Col md={6}>
                <div style={{
                  textAlign: 'center'
                }}>
                  <ShoppingBag color='#30b153' size={48} />

                  <h3 style={{
                    color: '#30b153',
                    fontWeight: 500,
                    lineHeight: 1.6,
                    marginTop: 40
                  }}>
                    Encomenda realizada com sucesso.
                  </h3>

                  <div style={{
                    color: '#888',
                    fontSize: 16
                  }}>
                    Verifique o seu email, caso não encontre o email que enviámos, <br /> tente ver na pasta de spam.
                  </div>
                </div>
              </Col>
            </Row>
          </Grid>
        )}
      </div>
    );
  }
};

export default OrderConfirmation;
