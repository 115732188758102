import styled from 'styled-components';

export const OrderTableContainer = styled.div`
  margin-top: 45px;
  border: 1px #ddd solid;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 3px 0 #f9f9f9;

  table {
    th {
      font-size: 11px;
      text-transform: uppercase;
      color: #999;
      font-weight: 500;
      padding: 10px;
    }

    img {
      width: 120px;
      border-radius: 6px;
      vertical-align: middle;
      margin: 0;
    }

    td {
      font-size: 15px;
      padding: 10px;
    }
  }
`;

export const OrderForm = styled.form`
  margin-top: 45px;
  border: 1px #ddd solid;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 3px 0 #f9f9f9;

  label {
    display: block;
    font-size: 12px;
    color: #888;
    text-transform: uppercase;
    font-weight: 500;
  }

  input {
    display: block;
    width: 100%;
    padding: 7px;
    font-size: 13px;
    margin-bottom: 25px;
  }
`;
